<template>
      <div class=" mt-tb-1">
<table cellspacing="0" border="0">
    <tbody class="tbody tbody-window">

        <tr class="bg-tb-title">
            <td class="td_bg_1 p-12p"><strong class="fs-20p">{{currentLanguage.body_text_dichvu_pdv_TT }}</strong></td>
            <td class="td_bg_2 text-center p-12p"><strong class="fs-20p">{{currentLanguage.body_text_dichvu_pdv_PHIDICHVUTAICHINH }}</strong></td>
            <td class="td_bg_3 p-12p"><strong class="fs-20p">{{currentLanguage.body_text_dichvu_pdv_MUCPHI }}</strong></td>
        </tr>

        <tr>
            <td class="td_bg_1"><strong>I</strong></td>
            <td class="td_bg_2"><b>{{currentLanguage.body_text_dichvu_pdv_PHITHUDOIVOIBENNHANTHANHTOAN }}</b></td>
            <td class="td_bg_3"></td>
        </tr>
        <tr>
            <td class="td_bg_1"><span></span></td>
            <td class="td_bg_2 "><span>Dùng Ví Vimass quét QR âm thanh cửa hàng (theo %, tối thiểu 250đ) </span></td>
            <td class="td_bg_3">0.02%</td>

        </tr>
        <tr>
            <td class="td_bg_1"><span></span></td>
            <td class="td_bg_2 "><span>Dùng ứng dụng ngân hàng quét QR âm thanh cửa hàng (theo % tối thiểu 500đ)  </span></td>
            <td class="td_bg_3">0.02%</td>

        </tr>
        <tr>
            <td class="td_bg_1"><span></span></td>
            <td class="td_bg_2 "><span>Phí quản lý thu hộ tiền mặt (theo giao dịch) </span></td>
            <td class="td_bg_3">250đ</td>

        </tr>
        <tr>
            <td class="td_bg_1"><span></span></td>
            <td class="td_bg_2 "><span>{{currentLanguage.body_text_dichvu_pdv_DungViVimassThedanangthanhtoandenvichuyenthuhoacquetVNPAYQR }}</span></td>
            <td class="td_bg_3">0%</td>

        </tr>
        <tr>
            <td class="td_bg_1"><span></span></td>
            <td class="td_bg_2 "><span>{{currentLanguage.body_text_dichvu_pdv_Ruttoanbotientuvichuyenthuvetaikhoannganhangluc6h8h10h12h14h16h18hsotientoithieu }}</span></td>
            <td class="td_bg_3">0,02%</td>

        </tr>

        <tr>
            <td class="td_bg_1"><span></span></td>
            <td class="td_bg_2 "><span>{{currentLanguage.body_text_dichvu_pdv_DungungdungnganhangquetVietQRthanhtoanngaydentaikhoannganhangsotientoithieu }}</span></td>
            <td class="td_bg_3">0,03%</td>

        </tr>

        <tr>
            <td class="td_bg_1"><span></span></td>
            <td class="td_bg_2 "><span>{{currentLanguage.body_text_dichvu_pdv_DungViVimassThedanangthanhtoanngaydentaikhoannganhang }}</span></td>
            <td class="td_bg_3">0%</td>

        </tr>


        <tr>
            <td class="td_bg_1"><strong>II</strong></td>
            <td class="td_bg_2"><b>{{currentLanguage.body_text_dichvu_pdv_PHITHUDOIVOIBENTHANHTOAN }}</b></td>
            <td class="td_bg_3"></td>
        </tr>


        <tr>
            <td class="td_bg_1"><span></span></td>
            <td class="td_bg_2 "><span> {{currentLanguage.body_text_dichvu_pdv_DungViVimassThedanangthanhtoandenvichuyenthuhoacquetVNPAYQRsotientoithieu }}</span></td>
            <td class="td_bg_3">0,01%</td>

        </tr>

        <tr>
            <td class="td_bg_1"><span></span></td>
            <td class="td_bg_2 "><span> {{currentLanguage.body_text_dichvu_pdv_DungViVimassThedanangthanhtoanngaydentaikhoannganhang2 }} </span></td>
            <td class="td_bg_3">0.03%</td>

        </tr>





        <tr>
            <td class="td_bg_1"><strong>III</strong></td>
            <td class="td_bg_2"><b>{{currentLanguage.body_text_dichvu_pdv_YEUCAUCHUYENTIENCHUYENTIEN }}</b></td>
            <td class="td_bg_3"></td>



        </tr>
        <tr>
            <td class="td_bg_1"><span></span></td>
            <td class="td_bg_2 "> <span>{{currentLanguage.body_text_dichvu_pdv_Chuyentientuvidenvisotientoithieu200dongBaogomvicanhanvidoanhnghiepthedanangvichuyenthucuacuahang }}</span></td>
            <td class="td_bg_3">0.01%</td>


        </tr>


        <tr>
            <td class="td_bg_1"><span></span></td>
            <td class="td_bg_2 "> <span>{{currentLanguage.body_text_dichvu_pdv_Chuyentiennhanhdentaikhoannganhanghoacsothemoigiaodichtoida300trieudong }} </span></td>
            <td class="td_bg_3">0.03%</td>


        </tr>

        <tr>
            <td class="td_bg_1"><span></span></td>
            <td class="td_bg_2 "> <span>{{currentLanguage.body_text_dichvu_pdv_NaptienvaoviquaQRhoacquataikhoansotientoithieu }} </span></td>
            <td class="td_bg_3">0.03%</td>

        </tr>

        <tr>
            <td class="td_bg_1"><span></span></td>
            <td class="td_bg_2 "> <span>{{currentLanguage.body_text_dichvu_pdv_Ruttientuvivetaikhoannganhangmoigiaodichtoida300trieudong }} </span></td>
            <td class="td_bg_3">0.03%</td>

        </tr>

     

     
        <tr>
            <td class="td_bg_1"><strong>IV</strong></td>
            <td class="td_bg_2"><b>{{currentLanguage.body_text_dichvu_pdv_THUHOtheohopdong }}</b></td>
            <td class="td_bg_3">{{currentLanguage.body_text_dichvu_pdv_Thoathuan }}</td>

        </tr>
      

        <tr>
            <td class="td_bg_1"><span></span></td>
            <td class="td_bg_2">{{currentLanguage.body_text_dichvu_pdv_ChihoVNPOST }}</td>
            <td class="td_bg_3"> </td>

        </tr>
        <tr>
            <td class="td_bg_1"><span></span></td>
            <td class="td_bg_2">{{currentLanguage.body_text_dichvu_pdv_KhidungungdungnganhangquetQRmattruocthesotientoithieu200dong }}</td>
            <td class="td_bg_3"></td>

        </tr>
        <tr>
            <td class="td_bg_1"><span></span></td>
            <td class="td_bg_2">{{currentLanguage.body_text_dichvu_pdv_KhidungungdungQRVietnamquetQRmattruoctheThuhoquaThedanangsotientoithieu200dong }}</td>
            <td class="td_bg_3"></td>

        </tr>

      

        <tr>
            <td class="td_bg_1"><span></span></td>
            <td class="td_bg_2">{{currentLanguage.body_text_dichvu_pdv_BennhanlaviVimassgiatrigiaodichduoi1trieudong }}</td>
            <td class="td_bg_3"></td>


        </tr>
        <tr>
            <td class="td_bg_1"></td>
            <td class="td_bg_2">{{currentLanguage.body_text_dichvu_pdv_BennhanlaviVimassgiatrigiaodichtu1trieuden20trieudong }}</td>
            <td class="td_bg_3"></td>


        </tr>
        <tr>
            <td class="td_bg_1"></td>
            <td class="td_bg_2">{{currentLanguage.body_text_dichvu_pdv_BennhanlaviVimassgiatrigiaodichtren20trieudong }}</td>
            <td class="td_bg_3"></td>

        </tr>

        <tr>
            <td class="td_bg_1"><span></span></td>
            <td class="td_bg_2">{{currentLanguage.body_text_dichvu_pdv_Bennhanlataikhoansogiaodichmotthangduoi100000 }}</td>
            <td class="td_bg_3"></td>

        </tr>
        <tr>
            <td class="td_bg_1"></td>
            <td class="td_bg_2">{{currentLanguage.body_text_dichvu_pdv_Bennhanlataikhoansogiaodichmotthangtu100000den200000 }}</td>
            <td class="td_bg_3"></td>


        </tr>
        <tr>
            <td class="td_bg_1"></td>
            <td class="td_bg_2">{{currentLanguage.body_text_dichvu_pdv_Bennhanlataikhoansogiaodichmotthangtu200000den500000 }}</td>
            <td class="td_bg_3"></td>


        </tr>
        <tr>
            <td class="td_bg_1"></td>
            <td class="td_bg_2">{{currentLanguage.body_text_dichvu_pdv_Bennhanlataikhoansogiaodichmotthangtu500000den1000000 }}</td>
            <td class="td_bg_3"></td>


        </tr>
        <tr>
            <td class="td_bg_1"></td>
            <td class="td_bg_2">{{currentLanguage.body_text_dichvu_pdv_Bennhanlataikhoansogiaodichmotthangtren1000000 }}</td>
            <td class="td_bg_3"></td>

        </tr>


        <tr>
            <td class="td_bg_1"><strong>V</strong></td>
            <td class="td_bg_2"><b>{{currentLanguage.body_text_dichvu_pdv_CONGTHANHTOANTHE }}</b></td>
            <td class="td_bg_3">&nbsp;</td>

        </tr>
        <tr>
            <td class="td_bg_1"><span></span></td>
            <td class="td_bg_2">{{currentLanguage.body_text_dichvu_pdv_Dungthenoidiathanhtoanchodonvihoatdongtronglinhvucytegiaoducgiaothongcongcongdichvucongthuekinhdoanhxangdau }}</td>
            <td class="td_bg_3">1,500 đ </td>



        </tr>
        <tr>
            <td class="td_bg_1"></td>
            <td class="td_bg_2">{{currentLanguage.body_text_dichvu_pdv_DungthenoidiacuanganhangcoketnoithanhtoanthevoiVimass }}</td>
            <td class="td_bg_3">2,000 đ</td>


        </tr>
        <tr>
            <td class="td_bg_1"></td>
            <td class="td_bg_2">{{currentLanguage.body_text_dichvu_pdv_Dungthenoidiacuanganhangchuaketnoi }}</td>
            <td class="td_bg_3">0.65%</td>


        </tr>
        <tr>
            <td class="td_bg_1"></td>
            <td class="td_bg_2">{{currentLanguage.body_text_dichvu_pdv_DungtheVISAMasterCardJCBAMEX }}</td>
            <td class="td_bg_3">2,35% + 2,500 đ</td>


        </tr>

        <tr>
            <td class="td_bg_1"><strong>VI</strong></td>
            <td class="td_bg_2"><b>{{currentLanguage.body_text_dichvu_pdv_TIETKIEMTRUCTUYEN }}</b></td>
            <td class="td_bg_3"> {{currentLanguage.body_text_dichvu_pdv_Thoathuan }} </td>


        </tr>
        <tr>
            <td class="td_bg_1"><span></span></td>
            <td class="td_bg_2">{{currentLanguage.body_text_dichvu_pdv_HuyDong }}</td>
            <td class="td_bg_3"> </td>


        </tr>
        <tr>
            <td class="td_bg_1"><span></span></td>
            <td class="td_bg_2">{{currentLanguage.body_text_dichvu_pdv_Chovay }}</td>
            <td class="td_bg_3"> </td>


        </tr>

       


    </tbody>

</table>

</div>
</template>

<script>

  import { languages } from "@/components/languages";


  export default {
    components: {
   
     
    },
    data() {
      return {
    
  
        currentLanguage: languages.vi,
        lang: "",
       

      };
    },
    mounted() {
      this.lang = localStorage.getItem("languages");
    },
    created() {
    const savedLanguage = localStorage.getItem("languages");
    if (savedLanguage && languages[savedLanguage]) {
      this.currentLanguage = languages[savedLanguage];
    }
  },
    methods: {
      changeLanguage(lang) {
        this.currentLanguage = languages[lang];
        localStorage.setItem("languages", lang);
      window.location.reload();
      },
  
     
   
    },
  };
  </script>