<template>
    <div id="bandieuhanh" >
            <div>

                <table>

                    <tr>
                        <td colspan="2" style="text-align: center;font-size: 21px;">{{currentLanguage.body_text_congty_bdh_BANDIEUHANH}}</td>
                    </tr>
                    <tr style="background: #c3f0f7;">
                        <td style="width: 30%;text-align: center;text-transform: uppercase;font-weight: bold;">{{currentLanguage.body_text_congty_hdqt_HOTEN}}</td>
                        <td style="text-align: center;text-transform: uppercase;font-weight: bold;">{{currentLanguage.body_text_congty_hdqt_CHUCVU}}</td>
                    </tr>
                    <tr style="border-bottom: 1px solid #D7D7D7;">
                        <td style="width: 30%;">
                            <span class="hoten" style="float: left;">
                            {{currentLanguage.body_text_congty_hdh_tvt}}
                            </span>
                            <div style="width: 150px;border: 1px solid;float: right;">
                                <img src="img/avatar/TVT.jpg">
                            </div>
                        </td>
                        <td>
                            <span style="word-break: break-all;width: 100%;float: left;">{{currentLanguage.body_text_congty_bdh_ChutichHDQT}}<br>
                            {{currentLanguage.body_text_congty_bdh_Tonggiamdoc}}</span>
                            <span>Email: tranviettrung57@gmail.com</span>
                        </td>
                    </tr>
                    <tr style="border-bottom: 1px solid #D7D7D7;">
                        <td style="width: 30%;">
                            <span class="hoten" style="float: left;">
                            {{currentLanguage.body_text_congty_hdh_ntt}}
                            </span>
                            <div style="width: 150px;border: 1px solid;float: right;">
                                <img src="img/avatar/THANH.jpg">
                            </div>
                        </td>
                        <td>
                            <span style="word-break: break-all;width: 100%;float: left;">{{currentLanguage.body_text_congty_bdh_Photonggiamdocthuongtruc}}</span>
                            <span>Email: nguyentrongthanh0511@gmail.com</span>
                        </td>
                    </tr>

         
                    <tr style="border-bottom: 1px solid #D7D7D7;">
                        <td style="width: 30%;">
                            <span class="hoten" style="float: left;">
                            {{currentLanguage.body_text_congty_hdh_nmv}}
                            </span>
                            <div style="width: 150px;border: 1px solid;float: right;">
                                <img src="img/avatar/VU.jpeg">
                            </div>
                        </td>
                        <td>
                            <span style="word-break: break-all;width: 100%;float: left;">{{currentLanguage.body_text_congty_bdh_Truongphongmaychu}}</span>
                            <span>Email: manhvu515100070@gmail.com </span>
                        </td>
                    </tr>
                    <tr style="border-bottom: 1px solid #D7D7D7;">
                        <td style="width: 30%;">
                            <span class="hoten" style="float: left;">
                            {{currentLanguage.body_text_congty_hdh_nvt}}
                            </span>
                            <div style="width: 150px;border: 1px solid;float: right;">
                                <img src="img/avatar/danh2.jpg">
                            </div>
                        </td>
                        <td>
                            <span style="word-break: break-all;width: 100%;float: left;">{{currentLanguage.body_text_congty_bdh_PhongWebDesktopMiniVUHF}}</span>
                            <span>Email: buidanhhn@gmail.comm </span>
                        </td>
                    </tr>
                    <tr style="border-bottom: 1px solid #D7D7D7;">
                        <td style="width: 30%;">
                            <span class="hoten" style="float: left;">
                            {{currentLanguage.body_text_congty_hdh_cvc}}
                            </span>
                            <div style="width: 150px;border: 1px solid;float: right;">
                                <img src="img/avatar/chucuong.jpg">
                            </div>
                        </td>
                        <td>
                            <span style="word-break: break-all;width: 100%;float: left;">{{currentLanguage.body_text_congty_bdh_TruongphonglaptrinhiPhone}}</span>
                            <span>Email: chucuongv3vimass@gmail.com </span>
                        </td>
                    </tr>




                    <tr style="border-bottom: 1px solid #D7D7D7;">

                        <td style="width: 30%;">
                            <span class="hoten" style="float: left;">
                            {{currentLanguage.body_text_congty_hdh_httt}}
                            </span>
                            <div style="width: 150px;border: 1px solid;float: right;">
                                <img src="img/avatar/TRANG.jpg">
                            </div>
                        </td>
                        <td>
                            <span style="word-break: break-all;width: 100%;float: left;">{{currentLanguage.body_text_congty_bdh_Chanhvanphong}}</span>
                            <span>Email: hoangtrangpdu96@gmail.com </span>
                        </td>
                    </tr>
                    <tr style="border-bottom: 1px solid #D7D7D7;">

                        <td style="width: 30%;">
                            <span class="hoten" style="float: left;">
                            {{currentLanguage.body_text_congty_hdh_tvtinh}}
                            </span>
                            <div style="width: 150px;border: 1px solid;float: right;">
                                <img src="img/avatar/TVT.PNG">
                            </div>
                        </td>
                        <td>
                            <span style="word-break: break-all;width: 100%;float: left;">{{currentLanguage.body_text_congty_bdh_TruongphongMaketing}}</span>
                            <span>Email: tinhvimass@gmail.com </span>
                        </td>
                    </tr>
                </table>

            </div>
            <div  class="contentNew">

                <ul class="quyetdinh">
                    <li>

                        <a href="https://vimass.vn/vmbank/services/media/viewFile?id=23_02_02_hop_dong_cung_cap_dich_vu_thu_chi_ho_cua_Vimass.pdf" target="_blank">
                            <span style="color: #0066cc;float: left;">{{currentLanguage.body_text_congty_bdh_HopdongcungcapdichvuthuvachihoVimassQRVNngay02022023}}</span>
                        </a>

                    </li>
                    <li>

                        <a href="https://vimass.vn/vmbank/services/media/viewFile?id=23_2_1_Quyet_dinh_ve_phi_dich_vu_Vimass.pdf" target="_blank">
                            <span style="color: #0066cc;float: left;">{{currentLanguage.body_text_congty_bdh_QuyetdinhvephidichvutaichinhcuaVimass01022023}}</span>
                        </a>

                    </li>

                    <li>
                        <a href="https://vimass.vn/vmbank/services/media/viewFile?id=quyet_dinh_bo_nhiem_nguyen_duy_thinh.pdf" target="_blank">
                            <span style="color: #0066cc;float: left;">{{currentLanguage.body_text_congty_bdh_QuyetdinhbonhiemPhotonggiamdocngay31012023}}</span>
                        </a>
                    </li>
                    <li>
                        <a href="https://vimass.vn/vmbank/services/media/viewFile?id=quyet_dinh_bo_nhiem_dang_thanh_dat.pdf" target="_blank">
                            <span style="color: #0066cc;float: left;">{{currentLanguage.body_text_congty_bdh_QuyetdinhbonhiemTruongphongThietbingay31012023}}</span>
                        </a>
                    </li>
                    <li>
                        <a href="https://vimass.vn/vmbank/services/media/viewFile?id=22_7_1_Quyet_dinh_cung_cap_trang_thiet_bi_lam_viec_CBNV.pdf" target="_blank">
                            <span style="color: #0066cc;float: left;">{{currentLanguage.body_text_congty_bdh_QuyetdinhcungcaptrangthietbichoCBNVngay01072022}}</span>
                        </a>
                    </li>

                    <li>
                        <a href="https://vimass.vn/vmbank/services/media/viewFile?id=22_6_30_QD_Xu_phat_cua_TGD.pdf" target="_blank">
                            <span style="color: #0066cc;float: left;">{{currentLanguage.body_text_congty_bdh_QuyetdinhxuphatdoivoiCBNVkhongdapungnhiemvutoithieungay30062022}}</span>
                        </a>
                    </li>
                    <li>
                        <a href="https://vimass.vn/vmbank/services/media/viewFile?id=Quyet_dinh_lich_hop_28_06_2022.pdf" target="_blank">
                            <span style="color: #0066cc;float: left;">{{currentLanguage.body_text_congty_bdh_Quydinhvelichhopngay28062022}}</span>
                        </a>
                    </li>
                    <li>
                        <a href="https://vimass.vn/vmbank/services/media/viewFile?id=22_06_23_Quyet_dinh_tuyen_dung_CBNV_moi.pdf" target="_blank">
                            <span style="color: #0066cc;float: left;">{{currentLanguage.body_text_congty_bdh_QuyetdinhcuaTGDvetuyendungCBNVViMASStaiHoisovacacchinhanhngay23062022}}</span>
                        </a>
                    </li>
                    <li>
                        <a href="https://vimass.vn/vmbank/services/media/viewFile?id=QD_cua_TGD_ve_cac_khoan_chi_cua_CN.pdf" target="_blank">

                            <span style="color: #0066cc;float: left;">{{currentLanguage.body_text_congty_bdh_QDcuaTGDvecackhoanchitaiCNngay03062022}}</span>
                        </a>

                    </li>

                    <li>
                        <a target="_blank" href="https://vimass.vn/vmbank/services/media/viewFile?id=22_04_02_Quyet_dinh_dieu_chinh_luong_nhan_vien.pdf">
                            <span style="color: #0066cc;float: left;">{{currentLanguage.body_text_congty_bdh_QuydinhcuaTGDthaydoimucthunhapnhanvien02042022}}</span>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" href="https://vimass.vn/vmbank/services/media/viewFile?id=18_02_2022_Quy_dinh_VIMASS_QR.pdf">
                            <span style="color: #0066cc;float: left;">{{currentLanguage.body_text_congty_bdh_QuydinhveviectaosualuuVimassQR1822022}}</span>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" href="https://vimass.vn/vmbank/services/media/viewFile?id=22_2_11_Quyet_dinh_ve_phi_dich_vu_diem_thuong_va_diem_may.pdf">
                            <span style="color: #0066cc;float: left;">{{currentLanguage.body_text_congty_bdh_Quyetdinhvephidichvudiemthuongvadiemmay1122022}}</span>
                        </a>
                    </li>

                    <li>
                        <a target="_blank" href="https://vimass.vn/vmbank/services/media/viewFile?id=Quyet_dinh_cua_Tong_giam_doc.pdf">
                            <span style="color: #0066cc;float: left;">{{currentLanguage.body_text_congty_bdh_QuyetdinhxuphatCBNVkhonghoanthanhnhiemvutoithieu2612022}}</span>
                        </a>
                    </li>

                    <li>
                        <a href="https://vimass.vn/vmbank/services/media/viewFile?id=2021_11_30_Bien_Ban_Hop_HDQT_Vimass.pdf" target="_blank">
                            <span style="color: #0066cc;float: left;">{{currentLanguage.body_text_congty_bdh_BienbanhopHDQTVimassngay30112021pdf}}</span>
                        </a>
                    </li>
                    <li>
                        <a href="https://vimass.vn/vmbank/services/media/viewFile?id=QĐ_CUA_TGĐ_THUC_HIEN_THONG_TU_23.pdf">
                            <span style="color: #0066cc;float: left;">{{currentLanguage.body_text_congty_bdh_QuyetdinhcuaTongGiamDocveviectuanthutheoThongtuso232019TTNHNN}}</span>
                        </a>
                    </li>
                    <li>
                        <a href="#popupVayVon" name="open_popup" rel="PopupVimass">
                            <span style="color: #0066cc;float: left;">{{currentLanguage.body_text_congty_bdh_GopychoduthaoThongtusuadoiThongtu392014TTNHNNngay252019}}</span>
                        </a>
                    </li>
                    <li>
                        <a href="https://vimass.vn/vmbank/services/media/viewFile?id=QD_Phan_Cong_Nhan_Su_Ho_Tro_Khach_Hang.pdf" target="_blank">
                            <span style="color: #0066cc;float: left;">{{currentLanguage.body_text_congty_bdh_Phancongcanbohotrokhachhangngay2542019}}</span>
                        </a>
                    </li>
                    <li>
                        <a href="https://vimass.vn/vmbank/services/media/viewFile?id=Phan_cong_can_bo_trien_khai_cks.pdf" target="_blank">
                            <span style="color: #0066cc;float: left;">{{currentLanguage.body_text_congty_bdh_PhancongcanbotrienkhaicungcapdichvuchungthucCKScongcongngay1242019}}</span>
                        </a>
                    </li>
                </ul>


            </div>


        </div>
</template>
<script>

  import { languages } from "@/components/languages";


  export default {
    components: {
   
     
    },
    data() {
      return {
    
  
        currentLanguage: languages.vi,
        lang: "",
       

      };
    },
    mounted() {
      this.lang = localStorage.getItem("languages");
    },
    created() {
    const savedLanguage = localStorage.getItem("languages");
    if (savedLanguage && languages[savedLanguage]) {
      this.currentLanguage = languages[savedLanguage];
    }
  },
    methods: {
      changeLanguage(lang) {
        this.currentLanguage = languages[lang];
        localStorage.setItem("languages", lang);
      window.location.reload();
      },
  
     
   
    },
  };
  </script>