<template>
    <header class="d-flex justify-content-between align-items-center">
      <label for="toggle-1" class="toggle-menu" @click="toggleMenu"
        ><ul>
          <li></li>
          <li></li>
          <li></li></ul
      ></label>
      <!-- <strong @click="toggleMenu"> &#9776;	</strong> -->
      <nav  class="nav_center" style="padding: 0" :style="{ display: isMenuVisible ? 'block' : 'none' }">
        <ul style="list-style: none">
      
          <li :class="{ topmenuactive: activeTab === 'thidiem' }" @click="changeTab('thidiem')">
            <a id="urlThiDiem">{{ currentLanguage.header_text_theDaNang_ctThiDiem}} </a>
          </li>
          <li :class="{ topmenuactive: activeTab === 'caodang' }" @click="changeTab('caodang')"  style="display: none;">
            <a id="urlBai4">{{ currentLanguage.header_text_theDaNang_ctTaiTro}}</a>
          </li>

          <!-- <li :class="{ topmenuactive: activeTab === 'taitro' }" @click="changeTab('taitro')" >
            <a id="urlTaiTro" > {{ currentLanguage.header_text_theDaNang_ctTaiTro}}</a>
          </li> -->

         <li :class="{ topmenuactive: activeTab === 'th' }" @click="changeTab('th')" >
            <a id="urlBai1" > {{ currentLanguage.header_text_theDaNang_mamNonTieuHoc}} </a>
          </li>
          <li :class="{ topmenuactive: activeTab === 'thcs' }" @click="changeTab('thcs')" >
            <a id="urlBai2"> {{ currentLanguage.header_text_theDaNang_thcsThpt}} </a>
          </li>

          <li :class="{ topmenuactive: activeTab === 'thpt' }" @click="changeTab('thpt')" >
            <a id="urlBai3"> {{ currentLanguage.header_text_theDaNang_Thpt}}</a>
          </li>

      
          <!-- <li :class="{ topmenuactive: activeTab === 'daihoc' }" @click="changeTab('daihoc')" >
            <a id="urlBai5"> {{ currentLanguage.header_text_theDaNang_daiHoc}} </a>
          </li> -->
          <!-- --------------------------- -->
        <li :class="{ topmenuactive: activeTab === 'Yte' }" @click="changeTab('Yte')" style="display: none;">
            <a id="yte_urlBai1">{{ currentLanguage.header_text_diemThanhToan_sucKhoe}}</a>
          </li> 

          <li :class="{ topmenuactive: activeTab === 'BenhVienTinh' }" @click="changeTab('BenhVienTinh')"  style="display: none;">
            <a id="yte_urlBai2">{{ currentLanguage.header_text_theDaNang_TCNguoiNgheo_Tinhthanhpho }}</a>
          </li>

          <li :class="{ topmenuactive: activeTab === 'BenhVienHuyen' }" @click="changeTab('BenhVienHuyen')"  style="display: none;">
            <a id="yte_urlBai3">{{ currentLanguage.header_text_theDaNang_QuanHuyen}}</a>
          </li>

          <li :class="{ topmenuactive: activeTab === 'CoSoYTeXa' }" @click="changeTab('CoSoYTeXa')"  style="display: none;">
            <a id="yte_urlBai4">{{currentLanguage.header_text_theDaNang_phoCap_boyte}}</a>
          </li>

          <li :class="{ topmenuactive: activeTab === 'lienhe' }" @click="changeTab('lienhe')"  style="display: none;">
            <a >{{currentLanguage.header_text_lienHe }}</a>
          </li> 
        </ul>
      </nav>
      <div class="menu_right" style="display: flex">
        <a @click="changeLanguage('vi')" class="lang" style="padding: 5px"
      ><img src="img/img-item-vimass-2022/co/vi-VN.gif" />
    </a>
    <a @click="changeLanguage('en')" class="lang" style="padding: 5px"
      ><img style="width: 25px" src="img/img-item-vimass-2022/co/us.png" />
    </a>
    <a @click="changeLanguage('cn')" class="lang" style="padding: 5px"
      ><img style="width: 25px" src="img/img-item-vimass-2022/co/cn.jpg" />
    </a>
    <a @click="changeLanguage('kr')" class="lang" style="padding: 5px"
      ><img style="width: 25px ; " src="img/img-item-vimass-2022/co/kr.jpg" />
    </a>
    <a @click="changeLanguage('ja')" class="lang" style="padding: 5px"
      ><img style="width: 25px ; " src="img/img-item-vimass-2022/co/ja.jpg" />
    </a>

    <span
      class="clickShow"
      style="color: #303038; cursor: pointer; margin: auto"
      ><i class="fa fa-ellipsis-h" aria-hidden="true"></i
    ></span>
  
        <a id="urlHome" href="/" style="margin: 0 10px">
          <img style="width: 35px; height: 35px" src="img/home-icon-white-8.jpg"/>
        </a>
      </div>
    </header>
    <div class="row3">
      <main class="hoc container-fluid clear bv">
        
           <div v-show="showThiDiem" class="padding-top15 content" >  
            <div class="title-bai-viet" v-html="titleHtml"></div>
            <div v-html="contentHtml"></div>
           </div>

            <div v-show="showTaiTro" class="padding-top15 content" >  
              <div class="title-bai-viet" v-html="titleHtml"></div>
              <div v-html="contentHtml"></div>
            </div>

            <!-- tieu hoc-->
            <div v-show="showTieuHoc"  class="padding-top15 content">
              <div class="title-bai-viet" v-html="titleHtml"></div>
              <div v-html="contentHtml"></div>
            </div>
            <!-- thcs -->
            <div v-show="showTHCS" class="padding-top15 content">
              <div class="title-bai-viet" v-html="titleHtml"></div>
              <div v-html="contentHtml"></div>
            </div>
            <!-- THPT -->
            <div v-show="showTHPT" class="padding-top15 content">
              <div class="title-bai-viet" v-html="titleHtml"></div>
              <div v-html="contentHtml"></div>
            </div>
            <!-- CAO DANG -->
            <div v-show="showCaoDang"  class="padding-top15 content" >
               <div class="title-bai-viet" v-html="titleHtml"></div>
              <div v-html="contentHtml"></div>
            </div>
            <!-- dai hoc -->
            <div v-show="showDaiHoc" class="content">
              <div class="title-bai-viet" v-html="titleHtml"></div>
              <div v-html="contentHtml"></div>
            </div>

          <!--  educaticon--------------------------------------------------------------------------------------------- -->

          <div v-show="showYTe" class="content padding-top15" >
             <div class="title-bai-viet" v-html="titleHtml"></div>
             <div v-html="contentHtml"></div>
           </div>
           <!-- tieu hoc-->
           <div v-show="showBenhVienTinh" class="content padding-top15" >
             <div class="title-bai-viet" v-html="titleHtml"></div>
             <div v-html="contentHtml"></div>
           </div>
           <!-- BenhVienHuyen -->
           <div v-show="showBenhVienHuyen" class="content padding-top15" >
             <div class="title-bai-viet" v-html="titleHtml"></div>
             <div v-html="contentHtml"></div>
           </div>
           <!-- CoSoYTeXa -->
           <div v-show="showCoSoYTeXa" class=" content padding-top15">
             <div class="title-bai-viet" v-html="titleHtml"></div>
             <div v-html="contentHtml"></div>
           </div>
           <!-- context -->
           <div v-show="showLienHe" class=" content padding-top15">
             <div class="title-bai-viet" v-html="titleHtml"></div>
             <div v-html="contentHtml"></div>
           </div>
      </main>
    </div>
    <FooterView></FooterView>
  </template>
    <script>
  import FooterView from "@/views/Vimass/Footer/FooterView.vue";
  import { languages } from "@/components/languages";
  import { apiChiTietBaiViet } from "@/components/listService";
  import axios from "axios";
  export default {
    components: {
      FooterView
    },
    data() {
      return {
        activeTab: "thidiem",
        isMenuVisible: false,
        currentLanguage: languages.vi,
        lang: "",
        base64: "",
        base64Title: "",
        //show
        showThiDiem: true,
        showTaiTro: false,
        showTieuHoc: false,
        showTHCS: false,
        showTHPT: false,
        showCaoDang: false,
        showDaiHoc: false,
        showYTe: false,
        showBenhVienTinh: false,
        showBenhVienHuyen: false,
        showCoSoYTeXa: false,
        showLienHe: false,
 
        titleHtml: "", contentHtml: "",

        hasMounted: false,
      };
    },

    mounted() {
      if (window.innerWidth <= 768) {
        this.isMenuVisible = false;
      } else {
        this.isMenuVisible = true;
      }
      if (!this.hasMounted) {
        this.hasMounted = true; // Set the flag to true to indicate that mounted has been executed
        this.lang = localStorage.getItem("languages");
        if (this.lang == "vi") {
          document.title = "Vimass.vn - Giáo dục và y tế";
        } else if (this.lang == "en") {
          document.title = "Vimass.vn - Education";
        } else if (this.lang == "cn") {
          document.title = "Vimass.vn - 教育";
        } else if (this.lang == "kr") {
          document.title = "Vimass.vn - 교육과";
        }
        // window.location.href = this.lang + "/giaoduc#GiaoDuc/";
        document.getElementById("urlHome").setAttribute("href", this.lang);
        document.getElementById("urlThiDiem") .setAttribute("href", this.lang + "/giaoduc#thidiem/");
       // document.getElementById("urlTaiTro") .setAttribute("href", this.lang + "/giaoduc#taitro/");
        

      //  document.getElementById("urlBai1").setAttribute("href", this.lang + "/giaoduc#YTe/");
      }
  
      this.performPostRequest("16914910788912yc0k", "thidiem", this.lang);
    },
    created() {
      const savedLanguage = localStorage.getItem("languages");
      if (savedLanguage && languages[savedLanguage]) {
        this.currentLanguage = languages[savedLanguage];
      }
    },
    methods: {
      changeLanguage(lang) {
        this.currentLanguage = languages[lang];
        localStorage.setItem("languages", lang);
        // window.location.reload();
        window.location.href = "/" + lang + "/giaoduc#thidiem/";
      },
      toggleMenu() {
        this.isMenuVisible = true;
      },
      changeTab(tab) {
        if (window.innerWidth <= 768) {
          this.isMenuVisible = false;
        }
        this.lang = localStorage.getItem("languages");

        const tabStates = {
         thidiem :false,
         th: false,
         thcs :false,
         thpt: false,
         caodang :false,
         daihoc: false,
         Yte: false,
         BenhVienTinh: false,
         BenhVienHuyen: false,
         CoSoYTeXa: false,
         lienhe: false,
       };

      
        this.performPostRequest(this.getPostId(tab), tab, this.lang);
    
        tabStates[tab] = true;
        this.activeTab = tab; 
        this.showThiDiem = tabStates.thidiem;
        this.showTaiTro = tabStates.taitro;
        this.showTieuHoc = tabStates.th;
        this.showTHCS = tabStates.thcs;
        this.showTHPT = tabStates.thpt;
        this.showCaoDang = tabStates.caodang;
        this.showDaiHoc = tabStates.daihoc;
        this.showYTe = tabStates.Yte;
        this.showBenhVienTinh = tabStates.BenhVienTinh;
        this.showBenhVienHuyen = tabStates.BenhVienHuyen;
        this.showCoSoYTeXa = tabStates.CoSoYTeXa;
        this.showLienHe = tabStates.lienhe;

      },
      async performPostRequest(id, section) {
        this.lang = localStorage.getItem("languages");
  
        try {
          // Thực hiện POST request
          const response = await axios.post(apiChiTietBaiViet, {
            id: id,
            langId: "0",
          });
          if (this.lang === "vi") {
            this.base64 = response.data.result.content_vi;
            this.base64Title = response.data.result.title_vi;
          } else if (this.lang === "en") {
            this.base64 = response.data.result.content_en;
            this.base64Title = response.data.result.title_en;
          } else if (this.lang === "cn") {
            this.base64 = response.data.result.content_cn;
            this.base64Title = response.data.result.title_cn;
          }else if (this.lang === "kr") {
            this.base64 = response.data.result.content_kr;
            this.base64Title = response.data.result.title_kr;
          }else{
            this.base64 = response.data.result.content_vi;
            this.base64Title = response.data.result.title_vi;
          }
  
          var title = atob(this.base64Title);
          var decodedString = atob(this.base64);
  
          // Store content separately for each section
          if (section === "thidiem") {
            this.titleHtml = decodeURIComponent(escape(title));
            this.contentHtml = decodeURIComponent(escape(decodedString));
          } else if (section === "taitro") {
            this.titleHtml = decodeURIComponent(escape(title));
            this.contentHtml = decodeURIComponent(escape(decodedString));
          } else if (section === "th") {
            this.titleHtml = decodeURIComponent(escape(title));
            this.contentHtml = decodeURIComponent(escape(decodedString));
          } else if (section === "thcs") {
            this.titleHtml = decodeURIComponent(escape(title));
            this.contentHtml = decodeURIComponent(escape(decodedString));
          } else if (section === "thpt") {
            this.titleHtml = decodeURIComponent(escape(title));
            this.contentHtml = decodeURIComponent(escape(decodedString));
          } else if (section === "caodang") {
            this.titleHtml = decodeURIComponent(escape(title));
            this.contentHtml = decodeURIComponent(escape(decodedString));
          } else if (section === "daihoc") {
            this.titleHtml = decodeURIComponent(escape(title));
            this.contentHtml = decodeURIComponent(escape(decodedString));
          } if (section === "Yte") {
           this.titleHtml = decodeURIComponent(escape(title));
           this.contentHtml = decodeURIComponent(escape(decodedString));
         } else if (section === "BenhVienTinh") {
           this.titleHtml = decodeURIComponent(escape(title));
           this.contentHtml = decodeURIComponent(escape(decodedString));
         } else if (section === "BenhVienHuyen") {
           this.titleHtml = decodeURIComponent(escape(title));
           this.contentHtml = decodeURIComponent(escape(decodedString));
         } else if (section === "CoSoYTeXa") {
           this.titleHtml = decodeURIComponent(escape(title));
           this.contentHtml = decodeURIComponent(escape(decodedString));
         } else if (section === "lienhe") {
            this.titleHtml = decodeURIComponent(escape(title));
            this.contentHtml = decodeURIComponent(escape(decodedString));
          }
        } catch (error) {
          console.error("Error:", error);
        }
      },
      getPostId(tab) {
       // Sử dụng một đối tượng để ánh xạ tab với id của bài viết tương ứng
       const postIdMap = {
        //  GiaoDuc: "1694406782839x1e10",
        //  thidiem:"1697101199141EwMrG",
        
        thidiem:"16914910788912yc0k", // new
         taitro:"1688091199705sulw5",
         th: "1693973335517jxry9",
         thcs: "1693993651381zumxd",
         thpt:"1693973462420zm2fo",
         caodang:"16945770557827dnkj",
         daihoc:"1693973557639bchbw",
         Yte: "17295687643578FvBH",
         BenhVienTinh: "16944049354721oe4u",
         BenhVienHuyen: "1694405009668apaxz",
         CoSoYTeXa: "16914910788912yc0k",
         lienhe: "1690272053288yg3c7",
       };

       return postIdMap[tab];
     },
    },
  };
  </script>

  <style scoped>
  ul li a{
    color: white !important;;
  }

  .content{
    min-height: 500px; 
    /* background-image: none !important */
  }
  </style>